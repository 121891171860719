<template>
  <div class="content">
    <video class="video" src="https://image.4008737773.com/json/bg.mp4" autoplay muted />
    <Header>
      <!-- <img class="logo" src="@/assets/logo_white.png" alt="logo"> -->
      <span class="title font-16 color-fff">大数据监控后台</span>
    </Header>
    <main class="main">
      <div class="main-box">
        <aside class="aside color-fff">
          <h1 class="font-36">大数据监控系统</h1>
          <h3 class="font-26">全新模式，畅快工作</h3>
          <p class="font-16">用最快的速度，最佳的热情，最好的服务，将新鲜烫 手的披萨，干净整洁准确无误的送至顾客手中。</p>
        </aside>
        <div class="login">
          <span class="text mt-24 font-20 color-fff">账号密码登录</span>
          <div class="user icon mt-24">
            <input v-model="userName" class="input color-fff font-16" type="text">
          </div>
          <div class="password icon mt-24">
            <input v-model="userPassword" class="input color-fff font-16" type="password">
          </div>
          <div class="flex-between mt-24" v-if="authFlag">
            <div class="code icon" :class="authCode.length === 6 ? 'codeConfirm' : ''">
              <input v-model="authCode" class="input color-fff font-16" type="number" :max-length="6">
            </div>
            <button
              class="code-button button flex-center color-fff font-16"
              :class="sendAuthCode ? 'o-06' : ''"
              @click="getAuthCode"
              :disabled="sendAuthCode"
            >
              {{sendAuthCode ? `${authTime}s后重发` : '获取验证码'}}
            </button>
          </div>
          <button class="login-button button mt-24 flex-center color-fff font-16" @click="login">登 录</button>
          <div class="mt-8 flex-between">
            <span class="tips font-14" v-if="loginFlag">{{loginMsg}}</span>
            <span v-else />
            <router-link class="reset font-14" to="/register">忘记密码？</router-link>
          </div>
        </div>
      </div>
    </main>
    <footer class="foot font-16 color-fff">copyright&#169;2020 驰玛披萨技术部出品</footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { loginApi } from '@/axios'

let authTimeTimer = null
let authdata = {}

export default {
  name: 'login',
  components: {
    Header
  },
  data () {
    return {
      userName: '',
      userPassword: '',
      authCode: '',
      loginMsg: '',
      authInput: false,
      loginFlag: false,
      authFlag: false,
      sendAuthCode: false,
      authTime: 60
    }
  },
  methods: {
    async login () {
      if (!this.userName || !this.userPassword) {
        this.loginFlag = true
        this.loginMsg = '用户名或密码不能为空'
        return
      }
      if (this.authInput && this.authCode === '') {
        this.loginFlag = true
        this.loginMsg = '验证码不能为空'
        return
      }
      let url = loginApi + 'admin/user/login'
      let data = {
        name: this.userName,
        pwd: this.userPassword,
        type: 1
      }
      if (this.authCode) {
        data.code = this.authCode
      }
      let logindata = await this.$https.post(url, data)
      authdata = logindata.data
      if (logindata.code * 1 !== 200) {
        this.loginFlag = true
        this.loginMsg = logindata.msg
        return
      }
      if (authdata.flag * 1 !== 1) {
        this.loginFlag = true
        this.authFlag = true
        this.loginMsg = authdata.msg
        return
      }
      let ur = loginApi + 'admin/user/read'
      let tok = {
        token: authdata.token,
        type: 1
      }
      let authRes = await this.$https.post(ur, tok)
      authRes.token = tok.token
      let role = [1, 50, 143, 132, 183] // 132运营 143、50营运 1管理员 183新运营
      if (role.includes(authRes.role_id)) {
        global.store.set('DATA_USER', authRes)
        this.$router.push({ path: '/' })
      } else {
        this.loginFlag = true
        this.loginMsg = '权限不足，无法查看'
      }
    },
    // 验证码发送
    getAuthCode () {
      let url = loginApi + 'admin/user/sendCode'
      let params = {
        id: authdata.id
      }
      this.$https.post(url, params).then(({code, msg}) => {
        if (code !== 200) {
          this.loginMsg = msg || '验证码获取失败'
          this.loginFlag = true
          return
        }
        this.loginFlag = false
        this.sendAuthCode = true
        this.authTime = 60
        authTimeTimer = setInterval(() => {
          this.authTime -= 1
          if (this.authTime <= 0) {
            this.sendAuthCode = false
            clearInterval(authTimeTimer)
          }
        }, 1000)
      })
    }
  },
  destroyed () {
    clearInterval(authTimeTimer)
  }
}
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  padding: 0 32px;
  overflow: auto;
  h1, h3, p {
    margin: 0;
  }
  .mt-24 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 0.5rem;
  }
  .o-06 {
    opacity: 0.6;
  }
  .video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -99;
  }
  .logo {
    display: inline-block;
    width: 8.875rem;
    height: 1.4375rem;
  }
  .title {
    position: relative;
    padding-left: 18px;
    &::before {
      content: '';
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 16px;
      background-color: #fff;
    }
  }
  .main {
    padding-top: 184px;
    &-box {
      display: flex;
      justify-content: space-between;
      width: 1440px;
      margin: 0 auto;
      .aside {
        padding-top: 50px;
        width: 23rem;
        p {
          margin-top: 1rem;
        }
      }
      .login {
        position: relative;
        width: 22.625rem;
        height: 357px;
        padding: 0 1.5rem;
        background: linear-gradient(180deg, #12183D 0%, #121645 100%);
        border-radius: 6px;
        box-sizing: border-box;
        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: -2px;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          background: linear-gradient(60deg, #5f86f2, #a65ff2, #ccddff, #2483FF, #01D18A, #2EDBFF);
          background-size: 300% 300%;
          background-position: 0 50%;
          border-radius: 6px;
          animation: moveGradient 4s alternate infinite;
          z-index: -9;
        }
        .text {
          display: block;
        }
        .icon {
          position: relative;
          height: 2.5rem;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 12px;
            width: 14px;
            height: 14px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
          }
        }
        .user {
          width: 100%;
          &::before {
            background-image: url('../assets/user.png');
          }
        }
        .password {
          width: 100%;
          &::before {
            background-image: url('../assets/password.png');
          }
        }
        .code {
          width: 11.125rem;
          input {
            padding-right: 30px;
          }
          &::before {
            background-image: url('../assets/code.png');
          }
        }
        .codeConfirm {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 12px;
            width: 14px;
            height: 14px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
            background-image: url('../assets/correct.png');
          }
        }
        .input {
          height: 100%;
          width: 100%;
          padding-left: 36px;
          background-color: transparent;
          border-radius: 8px;
          border: 1px solid rgba(0, 252, 255, 0.5);
          outline: none;
          box-sizing: border-box;
        }
        .button {
          position: relative;
          height: 2.5rem;
          border-radius: 8px;
          background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 50%, #01D18A 100%);
          background-size: 200% 100%;
          animation: light 4s infinite linear;
          box-shadow: 0 0 2px #2483FF;
        }
        .code-button {
          flex: 1;
          margin-left: 1.5rem;
        }
        .login-button {
          width: 100%;
        }
        .tips {
          position: relative;
          padding-left: 1.375rem;
          color: #e6081f;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 14px;
            height: 14px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('../assets/caution.png');
            transform: translateY(-50%);
          }
        }
        .reset {
          color: #d8d8d8;
          text-decoration: none;
        }
      }
    }
  }
  .foot {
    position: fixed;
    bottom: 40px;
    opacity: 0.8;
    left: 50%;
    transform: translateX(-50%);
  }
  @keyframes light {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  @keyframes moveGradient {
    50% {
      background-position: 100% 50%;
    }
  }
}
</style>
